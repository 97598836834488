
import RcDomainObject from "@/domain/common/RcDomainObject";

import CheckListItem from "./CheckListItem.js";
import CheckListItemMap from "./CheckListItemMap.js";
import CheckListItemOptionsDomain from "./option/CheckListItemOptionsDomain.js";
import ChecklistItemResultsDomain from "@/domain/model/checklist/item/result/ChecklistItemResultsDomain.js";


export default class CheckListItemsDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this._storage = storage.CheckListItemStorage;
    this._options = new CheckListItemOptionsDomain(domain, storage);
    this._results = new ChecklistItemResultsDomain(domain, storage);
  }

  list() {
    return this._storage.list;
  }
  
  results() {
    return this._results;
  }
  
  options() {
    return this._options;
  }
  
  findById(objId) {
    var object = this._storage.map[objId];
    return new CheckListItem(this.domain, object);
  }
  
  findByAll() {
    var objects = this.list();
    var map = new CheckListItemMap(this.domain);
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var checklistItem = new CheckListItem(this.domain, item);
      map.add(checklistItem);
    }

    return map;
  }

}