
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Role from "./Role.js"

export default class RolesDomain extends RcDomainObject {
    
  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }

  list() {
    return this.storage.list;
  }

  findById(roleId) {
    var roles = this.list();
    for (var i = 0; i < roles.length; i++) {
      var item = roles[i];
      if (super.toId(item) === roleId) {
        return new Role(this.domain, item);
      }
    }

    return new Role(this.domain)
  }
}