
import RcDomainObject from "@/domain/common/RcDomainObject";

import Comment from "./Comment";

export default class CommentsDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }

  findById(objId) {
    var object = this.storage.map[objId];
    return new Comment(this.domain, object);
  }
}