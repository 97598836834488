
import RcDomainObject from "@/domain/common/RcDomainObject";
import TimesheetEntry from "./TimesheetEntry.js";

export default class TimesheetEntriesDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this._storage = storage;
  }
  
  list() {
    return this._storage.list;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new TimesheetEntry(this.domain, item);
      }
    }

    return new TimesheetEntry(this.domain, {});
  }
  
}