
import RcDomainObject from "@/domain/common/RcDomainObject.js";

import Company from "./Company.js";
import CompanyMap from "./CompanyMap.js";
import CompanyStats from "./CompanyStats.js";

export default class CompanysDomain extends RcDomainObject {
  
  constructor(domain, storeMap) {
    super(domain);
    this.storage = storeMap.companys_found;
    this.stats = storeMap.company_stats;
  }

  list() {
    return this.storage.list;
  }
  
  findAll() {
    var objects = this.list();
    var companys = new CompanyMap(this.domain);
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var company = new Company(this.domain, item);
      companys.add(company);
    }

    return companys;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Company(this.domain, item);
      }
    }

    return new Company(this.domain);
  }
  
  findStatsById(objId) {
    var item = null;
    for (var i = 0; i < this.stats.list.length; i++) {
      var listItem = this.stats.list[i];
      if (listItem.Company['@rid'] === objId) {
        item = listItem;
        break;
      }
    }
    return new CompanyStats(this.domain, item);
  }
}