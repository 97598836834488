
import RcDomainObject from "@/domain/common/RcDomainObject.js"
import Signature from "./Signature.js"

export default class SignaturesDomain extends RcDomainObject {

  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }

  list() {
    return this.storage.list;
  }

  findById(signatureId) {
    var items = this.list();
    for (var i = 0; i < items.length; i++) {
      var item = items[i];
      if (super.toId(item) == signatureId) {
        return new Signature(this.domain, item);
      }
    }

    return new Signature(this.domain);
  }
}