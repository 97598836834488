
import RcDomainObject from "@/domain/common/RcDomainObject.js";

import Image from "./Image.js";
import ImageMap from "./ImageMap.js";

export default class ImagesDomain extends RcDomainObject {
  
  constructor(domain, storeMap) {
    super(domain);
    this._storage = storeMap.ImageStorage;
  }

  list() {
    return this._storage.list;
  }
  
  findById(objId) {
    var object = this._storage.map[objId];
    return new Image(this.domain, object);
  }
  
  findByAll() {
    var objects = this.list();
    var map = new ImageMap(this.domain);
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var image = new Image(this.domain, item);
      map.add(image);
    }

    return map;
  }

}