
import RcDomainObject from "@/domain/common/RcDomainObject";

import CheckListItemOption from "./CheckListItemOption";
import CheckListItemOptionMap from "./CheckListItemOptionMap";

export default class CheckListItemOptionsDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this._storage = storage.CheckListItemOptionStorage;
  }

  list() {
    return this._storage.list;
  }
  
  findById(objId) {
    var object = this._storage.map[objId];
    return new CheckListItemOption(this.domain, object);
  }
  
  findByAll() {
    var objects = this.list();
    var map = new CheckListItemOptionMap(this.domain);
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var option = new CheckListItemOption(this.domain, item);
      map.add(option);
    }

    return map;
  }

}