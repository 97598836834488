
import RcDomainObject from "@/domain/common/RcDomainObject";

import Vehicle from "./Vehicle.js";
import VehicleMap from "./VehicleMap.js";

export default class VehiclesDomain extends RcDomainObject {
    
  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }
  
  list() {
    return this.storage.list;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Vehicle(this.domain, item);
      }
    }

    var vehicle = new Vehicle(this.domain);
    vehicle.withId(objId);
    return vehicle;
  }

  findByCompany(company) {
    var map = new VehicleMap(this.domain);
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      let vehicle = new Vehicle(this.domain, item);
      if (vehicle.company().id() === company.id()) {
        map.add(vehicle);
      }
    }
    return map;
  }
}