
import RcDomainObject from "@/domain/common/RcDomainObject";
import Timesheet from "./Timesheet.js";
import TimesheetEntriesDomain from "./TimesheetEntriesDomain.js";

export default class TimesheetsDomain extends RcDomainObject {
  
  constructor(domain, storage, entryStorage) {
    super(domain);
    this._storage = storage;
    this._entryStorage = entryStorage;
  }
  
  list() {
    return this._storage.list;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Timesheet(this.domain, item);
      }
    }

    return new Timesheet(this.domain, {});
  }
  
  entries() {
    return new TimesheetEntriesDomain(this.domain, this._entryStorage);
  }
}