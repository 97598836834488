

import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Answer from "./Answer.js";

export default class AnswersDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }

  list() {
    return this.storage.list;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Answer(this.domain, item);
      }
    }

    return new Answer(this.domain);
  }
  
    
}