
import RcDomainObject from "../../common/RcDomainObject";
import Company from "./Company.js";
import ContentUtils from "@/utils/ContentUtils.js";

export default class CompanyStats extends RcDomainObject {
  
  static MODEL_NAME = 'CompanyStats';
  
  static Fields = {
    Company: "Company",
    CheckIns: "checkInCount",
    Fixed: "fixedCount",
    Inspections: "inspectionCount",
    Majors: "majorCount",
    Minors: "minorCount",
    Reported: "reportedCount",
    Timesheets: "timesheetCount",
    Missing: "missingCount",
    Incomplete: "incompleteCount",
    InProgress: "inProgressCount",
    MISSING_2_WEEKS: "missing2WeekCount",
    INCOMPLETE_2_WEEKS: "incomplete2WeekCount",
    Trailers: "trailerCount",
    USERS_ACTIVE: "userCount",
    USERS_INVITED: "userInvitedCount",
    Vehicles: "vehicleCount",
    Name: "companyName",
    Type: "type",
    MAJORS_ALL_COUNT : "majorAllCount",
    MINORS_ALL_COUNT: "minorAllCount",
    REPORTED_ALL_COUNT: "reportedAllCount",
    AMOUNT: "amount",
    DISTANCE_TRAVELLED: "distanceTravelled",
  }
  
  constructor(domain, data) {
    super(domain, data, CompanyStats.MODEL_NAME);
  }

  fields() {
    return CompanyStats.Fields;
  }
  
  find() {
    return this.domain.companys().findStatsById(this.id());
  }
  
  copy() {
    var data = this._copy();
    return new CompanyStats(this.domain, data);
  }
  
  type() {
    return this.getString(CompanyStats.Fields.Type);
  }
  
  amount() {
    return this.get(CompanyStats.Fields.AMOUNT);
  }
  
  companyName() {
    return this.getString(CompanyStats.Fields.Name);
  }
  
  company() {
    return new Company(this.domain, this.get(CompanyStats.Fields.Company))
  }
  
  inspections() {
    var value = this.getInt(CompanyStats.Fields.Inspections);
    if (!value) {
      value = 0;
    }
    return value;
  }
  
  reportedDefects() {
    return this.getInt(CompanyStats.Fields.Reported);
  }

  reportedMinors() {
    return this.getInt(CompanyStats.Fields.Minors);
  }

  reportedMajors() {
    return this.getInt(CompanyStats.Fields.Majors);
  }

  reportedDefectsAll() {
    return this.getInt(CompanyStats.Fields.REPORTED_ALL_COUNT);
  }

  reportedMinorsAll() {
    return this.getInt(CompanyStats.Fields.MINORS_ALL_COUNT);
  }

  reportedMajorsAll() {
    return this.getInt(CompanyStats.Fields.MAJORS_ALL_COUNT);
  }

  vehicles() {
    return this.getInt(CompanyStats.Fields.Vehicles);
  }
  
  trailers() {
    return this.getInt(CompanyStats.Fields.Trailers);
  }
  
  users() {
    return this.getInt(CompanyStats.Fields.USERS_ACTIVE);
  }
  
  usersInvited() {
    return this.getInt(CompanyStats.Fields.USERS_INVITED);
  }
  
  timesheets() {
    var value = this.getInt(CompanyStats.Fields.Timesheets);
    if (!value) {
      value = 0;
    }
    return value;
  }
  
  missing() {
    return this.getInt(CompanyStats.Fields.Missing);
  }
  
  incomplete() {
    return this.getInt(CompanyStats.Fields.Incomplete);
  }

  inProgress() {
    return this.getInt(CompanyStats.Fields.InProgress);
  }

  missing2Weeks() {
    return this.getInt(CompanyStats.Fields.MISSING_2_WEEKS);
  }
  
  incomplete2Weeks() {
    return this.getInt(CompanyStats.Fields.INCOMPLETE_2_WEEKS);
  }
  
  checkins() {
    var value = this.getInt(CompanyStats.Fields.CheckIns);
    if (!value) {
      value = 0; 
    }
    return value;
  }
  
  distanceTravelled() {
    if (this.has(CompanyStats.Fields.DISTANCE_TRAVELLED)) {
      return this.get(CompanyStats.Fields.DISTANCE_TRAVELLED);
    }
    return 0;
  }
  
  distanceTavelledDisplay() {
    return ContentUtils.sumDisplay(this.distanceTravelled());
  }
}