
import RcDomainObject from '@/domain/common/RcDomainObject.js';
import User from '@/domain/model/user/User.js';
import Employee from '@/domain/model/employee/Employee.js';
import Company from '@/domain/model/company/Company.js';
import CanadianEnglish from "@/domain/session/CanadianEnglish.js"
import RcTime from "@/domain/session/time/RcTime.js";

export default class RcSession extends RcDomainObject {
  
  constructor(domain, user) {
    super(domain);
    this._user = new User(domain, user);
    this.companyName = "";
    this._time = null;
  }

  start(logger) {
    this._time = new RcTime(logger);
    this.logger = logger;
  }
  
  employee() {
    var data = this.get(Employee.MODEL_NAME);
    var employee = new Employee(this.domain, data);
    return employee;
  }

  time() {
    return this._time;
  }
  
  user() {
    return this._user;
  }

  lang() {
    return new CanadianEnglish();
  }
  
  withoutCompany() {
    this.remove(Company.MODEL_NAME);
    return this;
  }
  
  with(object) {
    super.with(object);
    return this;
  }
  
  company() {
    var companyData = this.get(Company.MODEL_NAME);
    var company = new Company(this.domain, companyData);
    return company;
  }
  
}