
import RcDomainObject from "@/domain/common/RcDomainObject.js";

import CheckList from "./CheckList.js";
import CheckListMap from "./CheckListMap.js";

import CheckListItemsDomain from "./item/CheckListItemsDomain.js";
import ChecklistResultsDomain from "@/domain/model/checklist/result/ChecklistResultsDomain.js";

export default class CheckListsDomain extends RcDomainObject {
  
  constructor(domain, storeMap) {
    super(domain);
    this._storage = storeMap.CheckListStorage;
    this._items = new CheckListItemsDomain(domain, storeMap);
    this._results = new ChecklistResultsDomain(domain, storeMap);
  }

  list() {
    return this._storage.list;
  }
  
  items() {
    return this._items;
  }
  
  results() {
    return this._results;
  }
  
  findById(objId) {
    var object = this._storage.map[objId];
    return new CheckList(this.domain, object);
  }
  
  findAll() {
    var objects = this.list();
    var map = new CheckListMap(this.domain);
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var checklist = new CheckList(this.domain, item);
      map.add(checklist);
    }

    return map;
  }

}