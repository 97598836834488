
import RcDomainObject from "@/domain/common/RcDomainObject.js";

import Schedule from "./Schedule.js";

export default class SignaturesDomain extends RcDomainObject  {

  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }
  
  list() {
    return this.storage.list;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Schedule(this.domain, item);
      }
    }

    return new Schedule(this.domain);
  }
  
  findAll() {
    var list = [];
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      list[i] = new Schedule(this.domain, item);
    }
    
    return list;
  }
}