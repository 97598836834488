

import RcDomainObject from "@/domain/common/RcDomainObject";
import AppStats from './AppStats.js';


export default class AppStatsDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }
  
  list() {
    return this.storage.list;
  }
  
  first() {
    var size = this.list().length;
    if (size > 0) {
      var first = this.list()[size - 1];
      return new AppStats(this.domain, first);
    }
    return new AppStats(this.domain);
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new AppStats(this.domain, item);
      }
    }

    return new AppStats(this.domain);
  }
}