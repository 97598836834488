
import RcDomainObject from "@/domain/common/RcDomainObject";

import ChecklistResult from "./ChecklistResult.js";
import ChecklistResultMap from "./ChecklistResultMap.js";

export default class CheckListResultsDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this._storage = storage.ChecklistResultStorage;
  }

  list() {
    return this._storage.list;
  }
  
  findById(objId) {
    var object = this._storage.map[objId];
    return new ChecklistResult(this.domain, object);
  }
  
  findByAll() {
    var objects = this.list();
    var map = new ChecklistResultMap(this.domain);
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var result = new ChecklistResult(this.domain, item);
      map.add(result);
    }

    return map;
  }

}