
import RcDomainObject from "../../common/RcDomainObject";

import StringUtils from "@/utils/StringUtils.js";
import CompanyStatsMap from "@/domain/model/company/CompanyStatsMap.js";

export default class AppStats extends RcDomainObject {
  
  static MODEL_NAME = "AppStats";
      
  static Fields = {
    NAME : 'name',
    TYPE: "type",

    DEFECTS_FIXED: "fixedCount",
    DEFECTS_MAJOR: "majorCount",
    DEFECTS_MINOR: "minorCount",
    DEFECTS_REPORTED: "reportedCount",

    CHECKIN_COUNT_TODAY: "checkInCountToday",
    CHECKIN_COUNT_TOTAL: "checkInCountTotal",
    CHECKIN_COUNT_PAID: "checkInCountPaid",
    
    TIMESHEET_COUNT_TODAY: "timesheetCountToday",
    TIMESHEET_COUNT_TOTAL: "timesheetCountTotal",
    TIMESHEET_COUNT_PAID: "timesheetCountPaid",
    
    INSPECTION_COUNT_TODAY: "inspectionCountToday",
    INSPECTION_COUNT_TOTAL: "inspectionCountTotal",
    INSPECTION_COUNT_PAID: "inspectionCountPaid",
    
    USER_COUNT: "userCount",
    USER_INVITED_COUNT: "employeesInvitedCount",
    USER_INACTIVE_COUNT: "employeesInactiveCount",
    EMPLOYEES_BY_COMPANY_MONTH: "employeesByCompanyMonth",
    
    VEHICLE_COUNT: "vehicleCount",
    TRAILER_COUNT: "trailerCount",
    VEHICLE_BILLABLE_COUNT: "vehiclesBillableCount",
    VEHICLES_BY_COMPANY_MONTH: "vehiclesByCompanyMonth",
    
    COMPANY_COUNT_TOTAL: "companyCountTotal",
    COMPANY_COUNT_PAID: "companyCountPaid",
    COMPANY_COUNT_NEW: "companyCountNew",
    
    INVOICE_YEAR_TOTAL: "invoiceYearTotal",
    INVOICE_YEAR_UNPAID_TOTAL: "invoiceYearUnpaidTotal",

    INVOICE_LIFETIME_TOTAL: "invoiceLifeTimeTotal",
    INVOICE_LIFETIME_UNPAID_TOTAL: "invoiceLifeTimeUnpaidTotal",

    INVOICE_UNPAID_YTD: "invoicesUnpaidYtd",
    INVOICE_UNPAID_LT: "invoicesUnpaidLt",

    INVOICE_PAID_YTD: "invoicesPaidYtd",
    INVOICE_PAID_LT: "invoicesPaidLt",

    INVOICE_PAID_LM: "invoicesPaidLm",
    INVOICE_UNPAID_LM: "invoicesUnpaidLm",

    INVOICE_PAID_TOTAL_LM: "invoicesPaidTotalLm",
    INVOICE_UNPAID_TOTAL_LM: "invoicesUnpaidTotalLm",

    INVOICE_MONTH_TOTAL: "invoiceMonthTotal",
    
    INSPECTIONS_BY_COMPANY_MONTH: "inspectionsByCompanyMonth",
    INSPECTIONS_BY_COMPANY_TODAY: "inspectionsByCompanyToday",
    
    TIMESHEETS_BY_COMPANY_MONTH: "timesheetsByCompanyMonth",
    TIMESHEETS_BY_COMPANY_TODAY: "timesheetsByCompanyToday",

    CHECKINS_BY_COMPANY_MONTH: "checkinsByCompanyMonth",
    CHECKINS_BY_COMPANY_TODAY: "checkinsByCompanyToday",
  }
  
  constructor(domain, data) {
    super(domain, data, AppStats.MODEL_NAME);
  }

  forDisplay(value) {
    if (StringUtils.isEmpty(value)) {
      return "0";
    }
    return value;
  }

  invoicesUnpaidByCompanyYTD() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.INVOICE_UNPAID_YTD));
  }

  invoicesUnpaidByCompanyLT() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.INVOICE_UNPAID_LT));
  }

  invoicesPaidByCompanyYTD() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.INVOICE_PAID_YTD));
  }

  invoicesPaidByCompanyLT() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.INVOICE_PAID_LT));
  }

  invoicesUnpaidTotalLM() {
    return "$" + this.forDisplay(this.getDouble(AppStats.Fields.INVOICE_UNPAID_TOTAL_LM).toFixed(2));
  }

  invoicesPaidTotalLM() {
    return "$" + this.forDisplay(this.getDouble(AppStats.Fields.INVOICE_PAID_TOTAL_LM).toFixed(2));
  }

  invoicesPaidByCompanyLM() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.INVOICE_PAID_LM));
  }

  invoicesUnpaidByCompanyLM() {
    var data = this.get(AppStats.Fields.INVOICE_UNPAID_LM);
    return new CompanyStatsMap(this.domain, data);
  }

  inspectionsByCompanyThisMonth() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.INSPECTIONS_BY_COMPANY_MONTH));
  }
  
  inspectionsByCompanyToday() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.INSPECTIONS_BY_COMPANY_TODAY));
  }

  timesheetsByCompanyThisMonth() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.TIMESHEETS_BY_COMPANY_MONTH));
  }
  
  timesheetsByCompanyToday() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.TIMESHEETS_BY_COMPANY_TODAY));
  }

  checkinsByCompanyThisMonth() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.CHECKINS_BY_COMPANY_MONTH));
  }
  
  checkinsByCompanyToday() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.CHECKINS_BY_COMPANY_TODAY));
  }

  invoiceYearTotal() {
    return "$" + this.forDisplay(this.getDouble(AppStats.Fields.INVOICE_YEAR_TOTAL).toFixed(2));
  }

  invoiceMonthTotal() {
    return "$" + this.forDisplay(this.getDouble(AppStats.Fields.INVOICE_MONTH_TOTAL).toFixed(2));
  }
  
  invoiceYearUnpaidTotal() {
    return "$" + this.forDisplay(this.getDouble(AppStats.Fields.INVOICE_YEAR_UNPAID_TOTAL).toFixed(2));
  }

  invoiceLifeTimeTotal() {
    return "$" + this.forDisplay(this.getDouble(AppStats.Fields.INVOICE_LIFETIME_TOTAL).toFixed(2));
  }

  invoiceLifeTimeUnpaidTotal() {
    return "$" + this.forDisplay(this.getDouble(AppStats.Fields.INVOICE_LIFETIME_UNPAID_TOTAL).toFixed(2));
  }

  checkInsToday() {
    return this.forDisplay(this.getString(AppStats.Fields.CHECKIN_COUNT_TODAY));
  }

  checkInsTotal() {
    return this.forDisplay(this.getString(AppStats.Fields.CHECKIN_COUNT_TOTAL));
  }

  checkInsPaid() {
    return this.forDisplay(this.getString(AppStats.Fields.CHECKIN_COUNT_PAID));
  }

  checkInsPaidCount() {
    return this.getInt(AppStats.Fields.CHECKIN_COUNT_PAID);    
  }
  
  vehicles() {
    return this.forDisplay(this.getString(AppStats.Fields.VEHICLE_COUNT));
  }
  
  vehiclesBillableDisplay() {
    return this.forDisplay(this.getString(AppStats.Fields.VEHICLE_BILLABLE_COUNT));
  }
  
  vehiclesBillableCount() {
    return this.getInt(AppStats.Fields.VEHICLE_BILLABLE_COUNT);    
  }
  
  vehiclesByCompanyThisMonth() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.VEHICLES_BY_COMPANY_MONTH));
  }
  
  trailers() {
    return this.forDisplay(this.getString(AppStats.Fields.TRAILER_COUNT));
  }

  inspectionsToday() {
    return this.forDisplay(this.getString(AppStats.Fields.INSPECTION_COUNT_TODAY));
  }

  inspectionsTotal() {
    return this.forDisplay(this.getString(AppStats.Fields.INSPECTION_COUNT_TOTAL));
  }
  
  inspectionsPaid() {
    return this.forDisplay(this.getString(AppStats.Fields.INSPECTION_COUNT_PAID));
  }
 
  inspectionsPaidCount() {
    return this.getInt(AppStats.Fields.INSPECTION_COUNT_PAID);
  }
  
  timesheetsToday() {
    return this.forDisplay(this.getString(AppStats.Fields.TIMESHEET_COUNT_TODAY));
  }

  timesheetsTotal() {
    return this.forDisplay(this.getString(AppStats.Fields.TIMESHEET_COUNT_TOTAL));
  }

  timesheetsPaid() {
    return this.forDisplay(this.getString(AppStats.Fields.TIMESHEET_COUNT_PAID));
  }

  timesheetsPaidCount() {
    return this.getInt(AppStats.Fields.TIMESHEET_COUNT_PAID);
  }
  
  users() {
    return this.forDisplay(this.getString(AppStats.Fields.USER_COUNT));
  }

  usersInactive() {
    return this.forDisplay(this.getString(AppStats.Fields.USER_INACTIVE_COUNT));
  }

  usersInvited() {
    return this.forDisplay(this.getString(AppStats.Fields.USER_INVITED_COUNT));
  }

  employeesByCompanyThisMonth() {
    return new CompanyStatsMap(this.domain, this.get(AppStats.Fields.EMPLOYEES_BY_COMPANY_MONTH));
  }

  companyTotal() {
    return this.forDisplay(this.getString(AppStats.Fields.COMPANY_COUNT_TOTAL));
  }
  
  companyPayingTotal() {
    return this.forDisplay(this.getString(AppStats.Fields.COMPANY_COUNT_PAID));
  }

  companyCountNew() {
    return this.forDisplay(this.getString(AppStats.Fields.COMPANY_COUNT_NEW));
  }

  fields() {
    return AppStats.Fields;
  }
  
  find() {
    return this.domain.appStats().findById(this.id());
  }
  
  copy() {
    var data = this._copy();
    return new AppStats(this.domain, data);
  }
}