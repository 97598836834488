

export default class WebLogger {
    constructor(console, debug) {
      this._console = console;
      this._debug = debug;
    }

    info(msg, vargs) {
//      if (!this._debug) {
//        return;
//      }
      var out = msg;
      if (vargs) {
        if (Array.isArray(vargs)) {
          for (var i = 0; i < vargs.length; i++) {
            var replaceThis = "{" + i + "}";
            var withThis = vargs[i];
            if (withThis && withThis.data && withThis.modelName) {
              var type = withThis.modelName();
              if (withThis.data['name']) {
                withThis = withThis.data['name'] + "(" + withThis.id() + ")"
              } else {
                withThis = type + "(" + withThis.id() + ")";
              }
            }
            out = out.replace(replaceThis, withThis);
          }
        }
      }
      if (out) {
        var date = new Date();
        var ds = date.toString();
        out = ds.substring(0, ds.indexOf("GMT") - 1) + ": " + out;
        this._console.log(out);
      }
      return this;
    }
}