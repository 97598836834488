

import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Option from "./Option.js";


export default class OptionsDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }

  list() {
    return this.storage.list;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        var option = new Option(this.domain, item);
        return option;
      }
    }

    return new Option(this.domain);
  }
  
  findNotesMajor() {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var option = new Option(this.domain, item);
      if (option.isRemark() && option.isMajor()) {
        return option;
      }
    }

    return new Option(this.domain);
  }
  
  findNotesMinor() {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var option = new Option(this.domain, item);
      if (option.isRemark() && option.isMinor()) {
        return option;
      }
    }

    return new Option(this.domain);
  }
}