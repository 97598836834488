
import User from "./User.js";
import UserMap from "./UserMap.js";
import SignaturesDomain from "./SignaturesDomain.js";
import RcDomainObject from "@/domain/common/RcDomainObject.js";

export default class UsersDomain extends RcDomainObject {
  
  constructor(domain, storage, signatureStorage) {
    super(domain);
    this.storage = storage;
    this.signatureStorage = signatureStorage;
  }

  list() {
    return this.storage.list;
  }

  findAll() {
    var users = this.list();
    var userMap = new UserMap(this.domain);
    for (var i = 0; i < users.length; i++) {
      var item = users[i];
      var user = new User(this.domain, item);
      userMap.add(user);
    }
    return userMap;
  }

  findById(userId) {
    var users = this.list();
    for (var i = 0; i < users.length; i++) {
      var item = users[i];
      if (super.toId(item) == userId) {
        return new User(this.domain, item);
      }
    }


    var user = new User(this.domain);
    user.withId(userId);
    return user;
  }
  
  signatures() {
    return new SignaturesDomain(this.domain, this.signatureStorage);
  }
}