
import RcDomainObject from "../../common/RcDomainObject.js";
import Feature from "../feature/Feature.js";
import Company from "../company/Company.js";

export default class Asset extends RcDomainObject {
  
  static MODEL_NAME = "Asset";
      
  static FIELDS = {
    NAME : 'name',
    Feature: 'Feature',
    Discount: 'discount',
    Company: 'Company',
  }
  
  constructor(domain, data) {
    super(domain, data, Asset.MODEL_NAME);
  }

  fields() {
    return Asset.FIELDS;
  }
  
  find() {
    return this.domain.assets().findById(this.id());
  }
  
  name() {
    return this.getString(Asset.FIELDS.NAME);
  }
  
  copy() {
    var data = this._copy();
    return new Asset(this.domain, data);
  }
  
  feature() {
    return new Feature(this.domain, this.get(Asset.FIELDS.Feature));
  }
  
  discount() {
    return this.getString(Asset.FIELDS.Discount);
  }
  
  company() {
    return new Company(this.domain, this.get(Asset.FIELDS.Company));
  }
}