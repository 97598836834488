


import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Question from "./Question.js";

export default class QuestionsDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }

  list() {
    return this.storage.list;
  }
  
  findById(objId) {
    var objects = this.list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Question(this.domain, item);
      }
    }

    return new Question(this.domain);
  }
}